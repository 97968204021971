<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    en: {
    },
    pt: {
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,
    };
  },
  methods: {
    getContracts() {
      this.table.body = null

      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('contracts')
        .then(response => {
          if (response.data.status=='success') {
            this.table.body = response.data.list
          } else if (response.data.status=='empty') {
            this.statData = []
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body=='' || this.table.body==null) {
            this.table.empty = true
          }
        })
    }
  },
  mounted() {
    this.getContracts()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Meus Contratos') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Plano</th>
                    <th>Valor</th>
                    <th>Rendimento</th>
                    <th>Data</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td>{{ td.code }}</td>
                    <td>{{ td.plan.name }}</td>
                    <td>{{ td.value | currency }}</td>
                    <td>{{ td.plan.yield }}</td>
                    <td>
                      <template v-id="td.status == 'active'">{{ td.date.start }}</template>
                    </td>
                    <td>
                        <span v-if="td.status === 'pending'" class="badge badge-soft-warning font-size-12 text-uppercase">Pendente</span>
                        <span v-else-if="td.status === 'inactive'" class="badge badge-soft-info font-size-12 text-uppercase">Inativo</span>
                        <span v-else-if="td.status === 'active'" class="badge badge-soft-success font-size-12 text-uppercase">Ativo</span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>